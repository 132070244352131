import { Layout, Skeleton } from 'antd';

const LayoutLoading = () => {
  return (
    <Layout style={{ minHeight: '100vh' }} className='flex flex-row'>
      <div className='h-[100vh] w-[265px] hidden lg:flex' />
      <Layout>
        <div className='flex lg:hidden'>
          <Skeleton className='h-[100vh] w-full py-6 px-4' active />
        </div>
      </Layout>
    </Layout>
  );
};

export default LayoutLoading;
