import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import { APP_PREFIX_TRANSLATION_KEY } from '@constants/app';
// import AuthProvider from '@components/core/AuthProvider';

import dynamic from 'next/dynamic';
import LayoutLoading from '@components/layout/LayoutLoading';

const Homepage = dynamic(() => import('@features/home/page'), {
  loading: () => <LayoutLoading />,
});

export default function DashboardPage() {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t(APP_PREFIX_TRANSLATION_KEY + 'home_page')}</title>
      </Head>
      {/*<AuthProvider>*/}
      <Homepage />
      {/*</AuthProvider>*/}
    </>
  );
}
